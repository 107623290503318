<template>
    <b-row v-if="meeting && meeting.starts_at" class="animated-fast fadeInDown">
        <b-col sm="12">
            <a class="alert alert-danger d-block alert-meeting" href="" @click.prevent="openMeetingFrame">

                <span v-if="meeting.link_replay" class="mr-2">{{ $t('common.component.meeting_alert.replay') }}:</span>
                <i class="fad fa-video mr-2"></i>


                <span v-if="$i18n.locale === 'de'">{{ meeting.name_de }}</span>
                <span v-if="$i18n.locale === 'en'">{{ meeting.name_en }}</span>

                - {{ formatDate(meeting.starts_at) }}
            </a>

        </b-col>
    </b-row>
</template>
<style>
    .alert-meeting:hover {
        color: white;
    }
</style>
<script>
    export default {
        props: ['meeting'],
        methods: {
            formatDate(date) {
                return this.$moment.utc(date).local().format('Do MMMM, LT');
            },
            openMeetingFrame() {

                if (this.meeting.link_replay) {
                    this.$router.push({name: 'Appointment:Meeting', params: {vanity_url: this.meeting.vanity_url}});
                    return true;
                }

                this.$api.get('user/team/referral-link/meeting').then((response) => {
                    this.$swal.fire({
                        title: '<strong>' + this.$t('common.component.meeting_alert.popup.title') + '</strong>',
                        icon: 'info',
                        html:
                            this.$t('common.component.meeting_alert.popup.link_direct') + ':' +
                            '<div class="input-group mb-3">' +
                            '    <div class="input-group-prepend">' +
                            '        <span class="input-group-text"><i class="far fa-link"></i></span>' +
                            '    </div>' +
                            '    <div class="form-control">' + process.env.VUE_APP_MEETING_URL + '</div>' +
                            '</div>' +
                            this.$t('common.component.meeting_alert.popup.link_share') + ':' +
                            '<div class="input-group mb-3">' +
                            '    <div class="input-group-prepend">' +
                            '        <span class="input-group-text"><i class="far fa-link"></i></span>' +
                            '    </div>' +
                            '    <div class="form-control">' + process.env.VUE_APP_MEETING_URL + response.data.code + '</div>' +
                            '</div><p>' + this.$t('common.component.meeting_alert.popup.info') + '</p>',
                        showCloseButton: true,
                        focusConfirm: true,
                        confirmButtonText: this.$t('common.component.meeting_alert.popup.open') + ' <i class="fad fa-external-link ml-1"></i>'
                    }).then((response) => {
                        if (!response.dismiss) {
                            window.open(process.env.VUE_APP_MEETING_URL);
                        }
                    })
                });
            },
        }
    }


</script>