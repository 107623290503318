<template>
    <div v-if="state.data.length" class="card card-data-browser card-default border-danger animated-fast fadeInDown">
        <div class="card-header">
            <i class="card-title-icon" :class="icon"></i> {{ title }}
        </div>
        <div class="card-body p-0" :class="loading ? 'whirl' : ''">
            <div v-show="state.data.length" class="table-wrapper">
                <div class="table-responsive text-nowrap">
                    <table class="table table-striped table-bordered">
                        <thead>
                        <tr>
                            <th>{{ $t('wallet.component.orders.label') }}</th>
                            <th>{{ $t('wallet.component.orders.amount') }}</th>
                            <th>{{ $t('wallet.component.orders.status') }}</th>
                            <th>{{ $t('wallet.component.orders.created_at') }}</th>
                            <th></th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="item in state.data">
                            <td>{{ item.label }}</td>
                            <td>{{ item.amount | currency }} EUR</td>
                            <td>{{ $t('wallet.component.orders.statuses.' + item.status) }}</td>
                            <td class="text-right">{{ $moment.utc(item.created_at).local().format('L') }} <span class="d-inline-block" style="width: 7em">{{ $moment.utc(item.created_at).local().format('LTS')}}</span></td>
                            <td class="p-0 pt-1 text-center">
                                <b-btn variant="link" @click="payNow(item)">Pay Now</b-btn>
                                <b-btn @click="cancelOrder(item)" variant="link" v-if="item.cancelable"><i class="fad fa-trash-alt text-secondary" style="font-size: 1.3em"></i></b-btn>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div v-show="!state.data.length && !loading">
                <div class="m-3 alert alert-primary">
                    {{ $t('wallet.component.orders.empty') }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import TableDataBrowser from '@/modules/Wallet/components/TableDataBrowser.vue';
    import router from "../../../router";

    export default {
        extends: TableDataBrowser,
        data() {
            return {
                title: this.$t('wallet.component.orders.outstanding.heading'),
                icon: 'fad fa-file-invoice-dollar',
                types: [],
                selectedType: undefined,
                endpoint: '/wallet/orders/outstanding',
            }
        },
        methods: {
            init: function () {
                this.refresh();
            },
            payNow: function(order) {
              if (order.type === 'bank_transfer' || order.type === 'cash_payment') {
                router.push({
                  name: 'Wallet:OrderBankDetails', params: {
                    order_label: order.label
                  }
                })
              } else if (order.type === 'canner_pay') {
                location.href = order.provider_data.payment_url;
              }
            },
            cancelOrder: function (order) {
                this.$swal.fire({
                    title: this.$t('wallet.component.orders.cancel.title'),
                    text: this.$t('wallet.component.orders.cancel.text', {order: order.label}),
                    showCancelButton: true
                }).then(response => {
                    if (response.value) {
                        this.$api.delete('wallet/orders/' + order.label).then(response => {
                            this.refresh();
                        })
                    }
                })
            }
        }
    }
</script>
